import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
css`
    .buttons-container {
        position: absolute;
        color: white;
        left: 0.55em;
        top: 1.55em;
        font-size: 2em;
        margin-top: .5em;
        display: flex;

        .button-wrapper {
            height: 1.5em;
            width: 1.5em;
            background: rgba(0,0,0,0.56);
            border-radius: .5em;
            position: relative;
            margin-right: .25em;

            a {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }

            &:hover {
                background: rgba(0,0,0,0.72);
                cursor: pointer;
            }
        }
    }

    .meet-container {
        iframe {
            width: 100%;
            height: 70vh;
        }
    }

    &.fullscreen-true {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 9999;
        
        .meet-container {
            height: 100%;

            .buttons-container {
                top: .55em;
            }
            
            iframe {
                height: 100%;
            }
        }
    }

    @media (max-width: ${variables.mobileL}) {
        .buttons-container {
            display: flex;
            margin-top: 0;

            .toggle {
                display: block;
            }
        }
    }
`;
